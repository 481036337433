<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-row :gutter="48">
        <a-col :md="24" :sm="24">
          <span class="table-page-search-submitButtons">
            <a-button style="float: right;margin-bottom:10px" @click="goAdd">添加课程分类</a-button>
          </span>
        </a-col>
      </a-row>
    </div>
    <a-table :columns="columns" :dataSource="tableData" :loading="tableLoading" rowKey="id">

      <span slot="action" slot-scope="text, record">
        <a @click="goEdit(record)">编辑</a>
        <a-divider type="vertical" />
        <a-popconfirm v-if="tableData.length" title="确定删除该分类?" @confirm="() => onDelete(record.id)">
          <a href="javascript:;">删除</a>
        </a-popconfirm>
      </span>
    </a-table>

  </a-card>
</template>

<script>
import { courseCategory, courseCategoryDelete } from '@/api/course'

const columns = [
  {
    title: 'ID',
    dataIndex: 'id'
  },
  {
    title: '名称',
    dataIndex: 'title'
  },
  {
    title: '描述',
    dataIndex: 'notes'
  },
  {
    title: '操作',
    width: '150px',
    scopedSlots: { customRender: 'action' }
  }]

export default {
  name: 'CourseCategory',
  data() {
    return {
      description: '列表使用场景：后台管理中的权限管理以及角色管理，可用于基于 RBAC 设计的角色权限控制，颗粒度细到每一个操作类型。',
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      // 高级搜索 展开/关闭
      advanced: false,
      // form
      form: this.$form.createForm(this),
      columns,
      tableData: [],
      tableLoading: false,
      ModalText: '确定删除？'
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.tableLoading = true
      new Promise((resolve, reject) => {
        courseCategory().then(response => {
          if (response.status === 'success') {
            resolve(response)
          } else {
            reject(response)
          }
        })
      }).then(res => {
        this.tableData = res.data
      }).catch(error => {
        this.$notification.error({
          message: error.status,
          description: error.message,
          duration: 4
        })
      }).finally(() => {
        this.tableLoading = false
      })
    },
    goAdd() {
      this.$router.push({ 'name': 'CourseCategoryEdit' })
    },
    goEdit(record) {
      this.$router.push({ 'name': 'CourseCategoryEdit', query: { id: record.id } })
    },
    onDelete(id) {
      new Promise((resolve, reject) => {
        courseCategoryDelete({ 'id': id }).then(response => {
          if (response.status === 'success') {
            resolve(response)
          } else {
            reject(response)
          }
        })
      }).then(res => {
        // 删除成功
        this.$notification.success({
          message: res.status,
          description: res.message
        })
        this.loadData()
      }).catch(error => {
        this.$notification.error({
          message: error.status,
          description: error.message
        })
      })
    }
  }
}
</script>
